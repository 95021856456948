import React, { useState, useRef, useEffect } from "react";
// import Header from "../Home/Header";
import Header from "components/header2";

import BoxContainer from "../../components/BoxContainer";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { useForm } from "react-hook-form";
import ThankYouPopUp from "../../components/PopUp/ThankYou/index";
import textResolver from "../../core/utils/text-resolver";
import { useLocation } from "@gatsbyjs/reach-router";
import { useContact } from "../../hooks/Forms/useContact";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import callIcon from "../../assets/temporary-images/call-icon.svg";
import emailIcon from "../../assets/temporary-images/email-icon.svg";
import formCommonPostData from "../../core/form-common-post-data";

const ContactUs = ({
  data,
  background = "bg-circleGradient bg-cover",
  headerData,
}) => {
  const { dataType = 0 } = data;
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const captchaRef = useRef(null);
  const { mutateAsync } = useContact();
  const location = useLocation();
  const [normalHeader, setNormalHeader] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (token) {
    const postData1 = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      country_id: data.country_id,
      phone: phonenumber + data.phone,
      company: data.company,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      setOpen(true);
      reset();
    });
    // }
    // else {
    //   alert("Please verify captcha");
    // }
  };

  // const handleVerify = () => {
  //   setToken(true);
  // };

  const listenToScroll = () => {
    let heightToHideFrom = 0;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      normalHeader && setNormalHeader(false);
    } else {
      setNormalHeader(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* {!normalHeader && <Header data={headerData} stickyHeader={true} />} */}

      <BoxContainer
        background={background}
        className="0px:px-5 2xl:px-0 pt-[6rem]"
      >
        {/* {normalHeader && <Header data={headerData} />} */}

        <div className="max-w-6xl mx-auto mt-9">
          <Text
            as="div"
            className="text-light-black text-[65px] text-center leading-[80px] font-primary font-extrabold pb-2"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          <Text
            as="div"
            className="text-lg text-center leading-[35px] text-blue font-opensans mb-12"
          >
            {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
          </Text>
        </div>
        <div className="flex justify-center sm:mx-8 md:mx-0 mb-28">
          <div
            className={`0px:w-full md:w-[587px] bg-white py-8 0px:px-6 sm:px-14 rounded-2xl shadow-2xl shadow-[#004C7430]`}
          >
            <div className="flex flex-col">
              <div className="antialiased">
                <div className="max-w-xl mx-auto divide-y md:max-w-4xl">
                  <div className="mt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="grid grid-cols-1 gap-2 text-[14px] text-black">
                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {textResolver(
                              data.placeholder.firstName,
                              "labelText"
                            )}
                          </Text>
                          <input
                            type="text"
                            name="first_name"
                            placeholder={textResolver(
                              data.placeholder,
                              "firstName"
                            )}
                            className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3 mb-4 rounded-[4px]"
                            {...register("first_name", {
                              required: true,
                            })}
                          />
                        </label>
                        {errors.name && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}
                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {textResolver(
                              data.placeholder.lastName,
                              "labelText"
                            )}
                          </Text>
                          <input
                            type="text"
                            name="last_name"
                            placeholder={textResolver(
                              data.placeholder,
                              "lastName"
                            )}
                            className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3 mb-4 rounded-[4px]"
                            {...register("last_name", {
                              required: true,
                            })}
                          />
                        </label>
                        {errors.email && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}

                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {textResolver(data.placeholder.email, "labelText")}
                          </Text>
                          <input
                            type="email"
                            name="email"
                            placeholder={textResolver(
                              data.placeholder,
                              "email"
                            )}
                            className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3 mb-4 rounded-[4px]"
                            {...register("email", {
                              required: true,
                            })}
                          />
                        </label>
                        {errors.email && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}
                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {" "}
                            {textResolver(
                              data.placeholder.phoneNumber,
                              "labelText"
                            )}
                          </Text>
                          <div className="flex 0px:flex-col sm:flex-row gap-x-1 sm:items-end">
                            <PhoneInput
                              international
                              defaultCountry="US"
                              placeholder="00-000-0000"
                              value={phonenumber}
                              onChange={(value) => setPhonenumber(value)}
                              className="text-black my-4 rounded-[3px] 0px:w-full sm:w-[110px] border-[1px] border-light-silver"
                            />
                            <input
                              type="text"
                              name="phonenumber"
                              placeholder={textResolver(
                                data.placeholder,
                                "phoneNumber"
                              )}
                              className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3 mb-4 rounded-[4px]"
                              {...register("phone", {
                                required: true,
                              })}
                            />
                          </div>
                        </label>
                        {errors.email && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}
                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {textResolver(
                              data.placeholder.company,
                              "labelText"
                            )}
                          </Text>
                          <input
                            type="text"
                            name="company"
                            placeholder={textResolver(
                              data.placeholder,
                              "company"
                            )}
                            className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3 mb-4 rounded-[4px]"
                            {...register("company", {
                              required: true,
                            })}
                          />
                        </label>
                        {errors.email && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}
                        <label className="block">
                          <Text
                            as="p"
                            className="font-opensans text-xs font-semibold"
                          >
                            {textResolver(
                              data.placeholder.message,
                              "labelText"
                            )}
                          </Text>
                          <textarea
                            placeholder={textResolver(
                              data.placeholder,
                              "message"
                            )}
                            className="mt-1 block w-full  py-2 px-3 border-light-silver border text-[14px] font-opensans mb-1 rounded-[4px]"
                            rows="3"
                            name="message"
                            {...register("message")}
                          ></textarea>
                        </label>
                        {errors.message && (
                          <span className="text-red text-sm font-opensans">
                            {textResolver(data, "fieldRequired")}
                          </span>
                        )}
                        <Text
                          as="span"
                          className="text-14px text-black font-opensans"
                        >
                          *By submitting this, you agree to Formal Translation's
                          Privacy Policy.
                        </Text>
                        {/* <HCaptcha
                        sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                        onVerify={handleVerify}
                        ref={captchaRef}
                        languageOverride={dataType === 1 ? "en" : "fr"}
                      /> */}
                        <input
                          type="hidden"
                          id="zc_gad"
                          name="zc_gad"
                          value=""
                        />
                        <Button
                          title={textResolver(data, "submitButtonText")}
                          className="w-full bg-irisPurple text-[14px] py-3 text-center text-white font-opensans font-semibold mt-1 uppercase rounded-full"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <ThankYouPopUp open={open} setOpen={setOpen} dataType={dataType} />
          </div>
        </div>
        <div className="pb-16">
          <div className="max-w-6xl mx-auto">
            <div className="flex 0px:flex-col md:flex-row gap-16 md:gap-28 justify-center">
              <div className="flex flex-col items-center">
                <img src={callIcon} alt="" />
                <Text
                  as="h3"
                  className="text-2xl text-blue font-primary font-bold pt-2 pb-3"
                >
                  Contact Us
                </Text>
                <Text
                  as="p"
                  className="text-base sm:text-lg text-blue font-opensans font-semibold"
                >
                  US: +1 985 239 0142 | UK: +44 1615 096140
                </Text>
              </div>
              <div className="flex flex-col items-center">
                <img src={emailIcon} alt="" />
                <Text
                  as="h3"
                  className="text-2xl text-blue font-primary font-bold pt-2 pb-3"
                >
                  Email Us
                </Text>
                <Text
                  as="p"
                  className="text-lg text-blue font-opensans font-semibold"
                >
                  support@tomedes.com
                </Text>
              </div>
            </div>
          </div>
        </div>
      </BoxContainer>
    </>
  );
};
export default ContactUs;
