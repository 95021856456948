import React from "react";
import Contact from "../LegalTranscription/Contactus";
import Seo from "../components/seo";
import Layout from "components/Layout";

function Index() {
  return (
    <Layout>
      <Seo
        title="Contact Us - Formal Translations"
        description="Contact Formal Translations today for straightforward answers, fast customer service, and certified translations online. We’re online 24/7, ready to help you."
        slug="contact-us/"
      />
      <Contact />
    </Layout>
  );
}
export default Index;
