export const endpoints = {
  forms: {
    getInTouchBanner: "/api/v1/location-get-in-touch",
    getInTouch: "/api/v1/get-in-touch",
    midform: "/api/v1/mid-contact-us",
    subscribe: "/api/v1/submit-subscribe",
    payAfterDelivery: "/api/v1/pay-after-delivery",
    contact: "/api/v1/contactus",
  },
  comments: {
    getComments: "/api/v1/comments?seoid={id}&page={pagenumber}",
    postComments: "/api/v1/comment/create",
  },
  reviews: {
    getReviews: "api/v1/get-reviews?page=pagenumber",
    postReviews: "api/v1/post-review",
  },
  quote: {
    getNewQuote: "/api/v1/get-a-quote",
    getQuoteInfo: "/api/v1/get-quote-info?quote_id={id}",
    uploadDocument: "/api/v1/upload-document",
    getFinalQuote: "/api/v1/get-final-quote",
    getTailoredQuote: "/api/v1/tailored-quote",
    saveQuote: "/api/v1/save-quote",
    placeOrder: "/api/v1/place-order",
    placeOrderAsGuest: "/api/v1/continue-as-guest",
    deleteFile: "/api/v1/delete-document?id={id}",
    getOrderDetail: "/api/v1/get-order-info?order_id={id}",
    applyCoupon: "/api/v1/apply-promocode",
    removeCoupon: "/api/v1/remove-promocode",
    paymentResponseOnPayPal: "/api/v1/payment-paypal",
    paymentResponseOnBlueSnap: "/api/v1/payment-bluesnap",
  },
  user: {
    login: "/api/v1/user-login",
    signup: "/api/v1/user-register",
  },
  formal: {
    uploadDocument: "/api/v1/formal/upload-document",
    saveQuote: "/api/v1/formal/save-quote",
    orderInfo: "/api/v1/formal/order-info",
    payment: "/api/v1/formal/payment",
    documentTypes: "/api/v1/formal/get-type-details",
    orderSummary: "/api/v1/formal/get-order-info",
    updateQuantity: "/api/v1/formal/update-item-qty",
    guestUser: "/api/v1/user-guest",
    deleteDocument: "/api/v1/formal/delete-document?id={9}",
    updateDocument: "/api/v1/formal/update-document",
  },
  payment: {
    payment_by_paypal: "/api/v1/formal/payment-paypal",
    payment_by_stripe: "/api/v1/formal/generate-stripe-payment-link",
  },
  aiData: {
    postAIResponse: "api/v2/formal-translate-ai-store",
  },
};
